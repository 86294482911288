import React from "react";
import styles from "./Podcast.module.scss";
import BackButton from "./BackButton";

export default ({
  episodeNo,
  episodeTitle,
  episodeDescription,
  episodeContent,
  episodeURL,
}) => {
  return (
    <div className={styles.root}>
      <div className="content-area">
        <div className="page-title-wrapper">
          <h1 className={`${styles.episodeTitle} page-title container-sm`}>
            {episodeTitle}（#{episodeNo}）
          </h1>
        </div>

        <div className="container-sm">
          <div className={styles.lead}>
            <div>{episodeDescription}</div>
            {episodeContent && <div>{episodeContent}</div>}
          </div>
          <div className={styles.episodeEmbed}>
            <iframe
              src={`https://www.youtube.com/embed/${episodeURL}`}
              title={episodeTitle}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          <BackButton />
        </div>
      </div>
    </div>
  );
};
