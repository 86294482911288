import React from "react";
import { Link } from "gatsby";
import styles from "./Podcast.module.scss";

export default () => {
  return (
    <div className={styles.backButton}>
      <Link to="/jp/podcast/">エピソード一覧</Link>
    </div>
  );
};
