import React from "react";
import Layout from "../../../components/Layout";
import Episode from "../../../components/Podcast/Episode";
import podcastEpisodes, { pageKeywords } from "../../../utils/podcastEpisodes";
import styles from "../../../components/Podcast/Podcast.module.scss";

export default ({ location }) => {
  const lang = "ja";

  // Page setting
  const episodeNo = 18;
  const pageImage = "";
  const episode = podcastEpisodes.find(ep => ep.id === episodeNo);
  const episodeContent = (
    <div className={styles.epSection}>
      <h2 className={styles.epSectionTitle}>フライフィッシングやってます</h2>
      <p>
        何も分からずに始めた釣りでしたが、先生に連れられて、初めてNYで釣りをしてから何気に3年が経ちました。最初が釣れないとつまらない、と文句を言っていた私。
      </p>
      <p>
        それでも、少しずつ道具の使い方やコツを覚えていき、ブルーギルやクラッピーなど、食いつきのいいサンフィッシュであれば、釣果が毎回出るようになってきました。そうすると、さらにフライフィッシングの世界に入り込んでいき、次へのステップアップとして、ついに自分でフライを作るときがやってきました。
      </p>
      <h2 className={styles.epSectionTitle}>NYのフライフィッシング専門店へ</h2>
      <p>
        ということで、 NYのマンハッタンにあるフライフィッシング専門店「
        <a
          href="https://www.urbanangler.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Urban Angler（アーバンアングラー）
        </a>
        」というお店に向かいます。こんなところに釣具屋さんがあるのか、というような雑居ビルの2階には、カラフルなフライのパーツがずらっと並んでます。お店のスタッフも親切で、作りたいフライを説明すると、これが使えるよ。と最適なフライパーツを探してくれます。
      </p>
      <p>
        釣りの中でもフライフィッシング専門店とだけあって、店内はすべてフライフィッシング！自作フライのためのパーツが壁2面に並んでいて、さらに専用の竿にウェイダー、靴やバッグ、帽子など、フライフィッシングに行くためのひと通りのものが置いてあります。
      </p>
      <h2 className={styles.epSectionTitle}>自作フライで魚を釣る</h2>
      <p>
        ほしいパーツを買い込んで帰り、さっそくフライ作り。先生に教えられながら作るも、最初は仕上がりイメージも湧いてないため、言われるがまま。しかし、1個作って分かりました。これはアクセサリ作りと同じだと。
      </p>
      <p>
        実は、昔アクセサリ作りにハマっていたことがあり、似たようなことを一生懸命やっていたのを、このフライ作りで思い出しました。一度コツが分かれば、あとは集中してイメージ通りに巻くだけ。初日に作ったフライは、決して美しい仕上がりとは言えなかったけれど、魚には大きな違いは分からないようで、その自作フライでいつもの公園で使ってみたところ、見事にいつも通り釣れました！
      </p>
      <p>実際に、自分で作ったフライで釣れるとうれしいものですね。</p>
      <p>
        そんな釣り初心者のステップアップのこと、NYのフライフィッシング専門店のことをポッドキャストで話しています。よかったら聞いてみてください↓
      </p>
    </div>
  );

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={episode.title}
      pageSlug={episode.slug}
      pageKeywords={pageKeywords}
      pageDescription={episode.description}
      pageImage={pageImage}
    >
      <Episode
        episodeNo={episodeNo}
        episodeTitle={episode.title}
        episodeDescription={episode.description}
        episodeURL={episode.embed}
        episodeContent={episodeContent}
      />
    </Layout>
  );
};
